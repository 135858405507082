import { type FC, memo, useCallback } from 'react'
import { signOut } from 'firebase/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Typography, Button } from '@mui/material'
import { auth } from '../firebase'
import { Center } from '../components'

const Home: FC = () => {
    const [user] = useAuthState(auth)

    const handleSignOut = useCallback(async () => {
        try {
            await signOut(auth)
        } catch (error) {
            if (error instanceof Error) {
                console.log(error.message)
            }
        }
    }, [])

    return (
        <Center>
            <Typography
                variant='h5'
                sx={{ marginBottom: '10px' }}
            >
                Welcome {user?.displayName}
            </Typography>
            <Button
                variant='contained'
                onClick={handleSignOut}
            >
                Sign Out
            </Button>
        </Center>
    )
}

export default memo(Home)

import { type FC, memo, useCallback } from 'react'
import { signInWithRedirect } from 'firebase/auth'
import { Button } from '@mui/material'
import { auth, googleAuthProvider } from '../../firebase'

const SignInForm: FC = () => {
    const handleSignIn = useCallback(() => {
        void signInWithRedirect(auth, googleAuthProvider)
    }, [])

    return (
        <Button variant='contained' onClick={handleSignIn}>
            Sign in with Google
        </Button>
    )
}

export default memo(SignInForm)

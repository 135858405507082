import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore/lite'
import { firebaseConfig } from './config'

export const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)

export const googleAuthProvider = new GoogleAuthProvider()

export const firestore = getFirestore(app)

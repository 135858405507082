import { type CSSProperties, type FC, memo } from 'react'

const style: CSSProperties = {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}

const Center: FC<{ children: any }> = ({ children }) => {
    return (
        <div style={style}>
            {children}
        </div>
    )
}

export default memo(Center)
